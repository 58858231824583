<template>
	<div class="container Auth mx-auto">
		<v-card class="d-flex flex-column mx-auto text-center" elevation="0">
			<v-card-title class="px-0 pt-0 mx-auto">
				<h1 class="heading">내국인/외국인 선택</h1>
			</v-card-title>
			<v-card-subtitle class="pl-0 text-base">
				내국인 인지 외국인인지 선택하신 후,
				<br v-if="isSmAndDown" />
				서비스 이용이 가능합니다
			</v-card-subtitle>

			<v-row align="center" justify="center">
				<div v-for="(item, i) in items" :key="i">
					<v-col>
						<v-hover v-slot="{ hover }">
							<v-card
								:elevation="hover ? 12 : 6"
								:class="{ 'on-hover': hover }"
								width="285"
								height="285"
								class="px-1 d-flex justify-space-around align-center"
							>
								<v-card-title class="text-h6 text-center">
									<v-row
										class="fill-height flex-column d-flex justify-center align-center"
									>
										<p class="font-weight-bold">
											{{ item.title }}
										</p>

										<div>
											<p class="text-base">
												{{ item.description }}
											</p>
										</div>
										<div v-if="item.type === 'foreigner'">
											<v-select
												:items="countries"
												v-model="selectedCountry"
												item-text="displayCountryLocale"
												item-value="country"
												hide-details
												dense
												outlined
											/>
										</div>

										<div class="mt-4">
											<v-btn
												color="primary"
												@click="updateCountry(item.type)"
												:disabled="
													item.type === 'foreigner' && !selectedCountry
												"
											>
												선택하기
											</v-btn>
										</div>
									</v-row>
								</v-card-title>
							</v-card>
						</v-hover>
					</v-col>
				</div>
			</v-row>
		</v-card>
	</div>
</template>

<script>
import { onMounted, ref, reactive, computed } from '@vue/composition-api'

import store from '@/store'
import { warningSwal } from '@/plugins/swalMixin'
import { useRouter, getVuetify } from '@core/utils'

import UserService from '@/services/UserService'
import UtilityService from '@/services/UtilityService'

const KR_NATIONALITY = 'KR'

export default {
	setup() {
		const $vuetify = getVuetify()
		const { router } = useRouter()
		const items = reactive([
			{
				title: '일반회원',
				description: '만 19세 이상 내국인',
				type: 'local',
			},
			{
				title: '외국인회원',
				description: '국내외에 거주하는 만 19세 이상 외국인',
				type: 'foreigner',
			},
		])
		const selectedCountry = ref('')
		const countries = ref([
			{
				country: '',
				displayCountryLocale: '선택안함	',
			},
		])

		const listCountries = async () => {
			try {
				const data = await UtilityService.listCountries()
				countries.value = [...countries.value, ...Object.values(data)]
			} catch (e) {
				console.log(e)
			}
		}

		const updateCountry = async type => {
			try {
				if (type === 'local') {
					const payload = {
						nationality: KR_NATIONALITY,
					}
					await UserService.putMe(payload)
					router.push({ name: 'auth-phone-number' })
				}

				if (type === 'foreigner') {
					const payload = {
						nationality: selectedCountry.value,
					}
					await UserService.putMe(payload)
					router.push({ name: 'auth-nationality-foreigner' })
				}
			} catch (e) {
				warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '인증정보를 불러오는데 문제가 발생되었습니다',
				)
			}
		}

		const isNationalitySet = () => {
			const profile = computed(() => store.getters['user/getMeDetail'])
			if (profile.value.nationality) {
				router.push({ name: 'mypage-dashboard' })
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		store.dispatch('app/setIsLogoWhite', false)

		onMounted(() => {
			listCountries()

			isNationalitySet()
		})

		return {
			items,
			countries,
			isSmAndDown,
			selectedCountry,

			updateCountry,
		}
	},
}
</script>

<style lang="scss" scoped>
.full-width {
	width: 100%;
	max-width: 100%;
}

.Auth {
	height: 100vh;
	margin-top: 10em;

	@media screen and (max-width: 768px) {
		margin-top: 5em;
	}

	.v-card {
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 22px;
		}
	}

	h1.heading {
		font-size: 25px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
		cursor: pointer;
	}

	button {
		width: 100%;
		min-width: 100% !important;
		min-height: 40px;
		font-size: 14px !important;
	}
}
</style>
